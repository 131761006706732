import { byKey, Order } from '@/utils/array';
import { defineStore } from 'pinia';
import { json } from '@sahnee/ajax';
import clone from '@sahnee/clone';
import deepMerge, { Flat, flatten, KeyOf, OptionalKeys, path, RequiredKeys, subtree, tree, Tree } from '@/utils/object';
import { $gettext } from 'vue-gettext';

interface State {
  operatingSystems: OperatingSystem[];
  loading: number;
  loaded: boolean;
}

interface GetParentsOpts {
  docTypes: ('operating_system_designation' | 'operating_system_version')[],
  excludeIds: string | string[],
  includeRoot: boolean,
}

const defaultOpts: GetParentsOpts = {
  docTypes: ['operating_system_designation'],
  excludeIds: [],
  includeRoot: false,
}

/**
 * An operating system base.
 */
interface OperatingSystemBase {
  _id: string;
  _rev: string;
  name: string;
  parent_id: string;
}

/**
 * An operating system.
 */
export type OperatingSystem = Designation | Version;

/**
 * A designation.
 */
export interface Designation extends OperatingSystemBase {
  doc_type: 'operating_system_designation';
  color: string;
}

/**
 * A version.
 */
export interface Version extends OperatingSystemBase {
  doc_type: 'operating_system_version';
  alias: string;
}

/**
 * An attachment
 */
export interface Attachment {
  name: string;
  data: string;
}

const defaultOperatingSystemBase: Omit<OperatingSystemBase, '_id' | '_rev'> = {
  name: '',
  parent_id: 'root',
}

export const defaultDesignation: Omit<Designation, '_id' | '_rev'> = {
  ...defaultOperatingSystemBase,
  doc_type: 'operating_system_designation',
  color: '',
}

export const defaultVersion: Omit<Version, '_id' | '_rev'> = {
  ...defaultOperatingSystemBase,
  doc_type: 'operating_system_version',
  alias: '',
}

function prepare(operatingSystem: Partial<OperatingSystem>) {
  let fullDownload: Partial<OperatingSystem> = {};
  let defaultKeys: string[] = [];
  // Designation
  if (operatingSystem.doc_type === 'operating_system_designation') {
    fullDownload = deepMerge(defaultDesignation, operatingSystem as Record<string, unknown>);
    defaultKeys = ['_id', '_rev', ...Object.keys(defaultDesignation)];
  }
  // Version
  else {
    fullDownload = deepMerge(defaultVersion, operatingSystem as Record<string, unknown>);
    defaultKeys = ['_id', '_rev', ...Object.keys(defaultVersion)];
  }
  const additionalKeys = Object.keys(fullDownload).filter(key => !defaultKeys.includes(key)) as (keyof OperatingSystem)[];
  for (const key of additionalKeys) {
    delete fullDownload[key];
  }
  return fullDownload;
}


/**
 * The operating system store.
 */
export const useOperatingSystemStore = () => {
  // Define a local store reference
  const useStore = defineStore('operatingSystem', {
    ///-------------------------------------------------------------------
    /// STATE
    ///-------------------------------------------------------------------
    state: (): State => {
      return {
        operatingSystems: [],
        loading: 0,
        loaded: false,
      }
    },
    ///-------------------------------------------------------------------
    /// GETTERS
    ///-------------------------------------------------------------------
    getters: {
      /**
       * Checks if the store is currently loading.
       * @returns Whether the store is loading or not.
       */
      isLoading: (state: State) => {
        return () => state.loading > 0;
      },
      /**
       * Search for operating systems by their ID.
       * @param ids The ID or an array of IDs of the operating systems.
       * @returns An array of operating systems from the store.
       */
      getById: (state: State) => {
        return ((ids: string | string[]) => {
          if (!Array.isArray(ids)) {
            ids = [ids];
          }
          const operatingSystems = state.operatingSystems.filter(operatingSystem => ids.includes(operatingSystem._id));
          return ids.length === 1 ? operatingSystems[0] : operatingSystems
        }) as ((ids: string | [string]) => OperatingSystem) & ((ids: string[]) => OperatingSystem[])
      },
      getName() {
        return (operatingSystem: OptionalKeys<OperatingSystem, '_id' | '_rev'>) => {
          if (!operatingSystem) {
            return '';
          }
          // Designation
          if (operatingSystem.doc_type === 'operating_system_designation') {
            return operatingSystem.name;
          }
          // Version
          const designation = this.getById(operatingSystem.parent_id);
          if (!designation) {
            return `${operatingSystem.name}${operatingSystem.alias ? ' <em>("' + operatingSystem.alias + '")</em>' : ''}`;
          }
          return `${designation.name} ${operatingSystem.name}${operatingSystem.alias ? ' <em>("' + operatingSystem.alias + '")</em>' : ''}`;
        };
      },
      getImage() {
        return (version: Version | string) => {
          if (typeof version === 'string') {
            version = this.getById(version) as Version;
          }
          if (!version) {
            return '';
          }
          const designation = this.getById(version.parent_id) as Designation;
          const designationName = designation.name.replaceAll('-', '--').replaceAll('_', '__').replaceAll(' ', '_');
          const versionName = version.name.replaceAll('-', '--').replaceAll('_', '__').replaceAll(' ', '_');
          return `https://img.shields.io/badge/${designationName}-${versionName}-${designation.color.slice(1)}`;
        };
      },
      /**
       * Search for the path to a given operating system by their ID.
       * @param ids The ID or an array of IDs of operating systems.
       * @returns The path, as an ordered array of the operating systems, or an array of paths.
       */
      getPathById: (state: State) => {
        return ((ids: string | string[]) => path(state.operatingSystems, ids)) as ((ids: string | [string]) => Designation[]) & ((ids: string[]) => Designation[][]);
      },
      isDesignation: (state: State) => {
        return ((ids: string | string[]) => {
          if (!Array.isArray(ids)) {
            ids = [ids];
          }
          const operatingSystems = state.operatingSystems.filter(operatingSystem => ids.includes(operatingSystem._id));
          return ids.length === 1 ? operatingSystems[0].doc_type === 'operating_system_designation' : operatingSystems.map(dl => dl.doc_type === 'operating_system_designation')
        }) as ((ids: string | [string]) => boolean) & ((ids: string[]) => boolean[])
      },
      getParents() {
        return (opts: Partial<GetParentsOpts> = {}) => {
          const actualOpts = { ...defaultOpts, ...opts };
          // Ensure we have a list of excluded IDs
          if (actualOpts.excludeIds === undefined) {
            actualOpts.excludeIds = [];
          } else if (typeof actualOpts.excludeIds === 'string') {
            actualOpts.excludeIds = [actualOpts.excludeIds];
          }
          // If we have excluded IDs also exclude any ID which is in the entire sub tree of that operating system
          actualOpts.excludeIds = actualOpts.excludeIds.filter(id => !!id).flatMap(id => this.getFlatSubtree(id).map(operatingSystem => operatingSystem._id));
          const root = actualOpts.includeRoot ? [{
            _id: 'root',
            name: $gettext('No parent designation'),
            doc_type: actualOpts.docTypes[0],
          } as OperatingSystem] : [];
          const operatingSystems = this.operatingSystems.filter(operatingSystem => {
            return actualOpts.docTypes.includes(operatingSystem.doc_type) && (actualOpts.excludeIds.length > 0
              ? !actualOpts.excludeIds.includes(operatingSystem._id) && !actualOpts.excludeIds.includes(operatingSystem.parent_id)
              : true);
          });
          return [
            ...root,
            ...operatingSystems
          ]
        }
      },
      /**
       * Search for designations within the operating systems.
       * @returns An array of designations from the store.
       */
      getDesignations: (state: State) => {
        return () => state.operatingSystems.filter(designation => designation.doc_type === 'operating_system_designation') as Designation[];
      },
      /**
       *
       */
      getDesignationsWithRoot: (state: State) => {
        return (id?: string) => {
          if (!id) {
            id = '';
          }
          return [
            {
              _id: 'root',
              name: $gettext('No parent designation'),
            },
            ...state.operatingSystems.filter(designation => id !== '' ? (designation.doc_type === 'operating_system_designation' && designation.parent_id !== id && designation._id !== id) : designation.doc_type === 'operating_system_designation')
          ] as RequiredKeys<Partial<Designation>, '_id' | 'name'>[];
        };
      },
      /**
       * Search for versions within the operating systems.
       * @returns An array of versions from the store.
       */
      getVersions() {
        return () => this.operatingSystems.filter(version => version.doc_type === 'operating_system_version') as Version[];
      },
      getGroupedVersions() {
        return (ids: string[]) => {
          const list = ids.map(id => this.getById(id)) as Version[];
          const versions = list.reduce((acc: Record<string, Version[]>, version) => {
            if (version.parent_id in acc) {
              acc[version.parent_id].push(version);
            } else {
              acc[version.parent_id] = [version]
            }
            return acc;
          }, {}); 
          return Object.fromEntries(
            Object.entries(versions)
              .map(([designationId, versionList]) => [
                designationId,
                versionList.sort((a, b) => {
                  const regex = new RegExp(/^[\d.]+$/);
                  if (regex.test(a.name) && regex.test(b.name)) {
                    return parseFloat(a.name) < parseFloat(b.name) ? 1 : -1;
                  }
                  return a.name < b.name ? 1 : -1;
                })
              ])
          );
        }
      },
      /**
       * Builds a tree hierarchy of all operating system designations & versions by connecting them via their `parent_id`.
       * @returns The tree hierarchy.
       */
      getTree: (state: State) => {
        // return () => tree(state.operatingSystems, 'parent_id');
        return (key: KeyOf<OperatingSystem> = 'name', order: Order = 'asc') => tree(clone(state.operatingSystems).sort(byKey(key, order)), 'parent_id');
      },
      getFlatTree() {
        return () => flatten(this.getTree());
      },
      getSubtree() {
        const tree = this.getTree();
        return ((ids: string | string[]) => {
          if (!Array.isArray(ids)) {
            ids = [ids];
          }
          const operatingSystems: Tree<OperatingSystem>[] = [];
          for (const id of ids) {
            const found = subtree(tree, id);
            if (found) {
              operatingSystems.push(found);
            }
          }
          return ids.length === 1 ? operatingSystems[0] : operatingSystems;
        }) as ((ids: string | [string]) => Tree<OperatingSystem>) & ((ids: string[]) => Tree<OperatingSystem>[])
      },
      getFlatSubtree() {
        return (ids: string | string[]) => {
          const operatingSystems: Flat<Tree<OperatingSystem>>[] = [];
          for (const operatingSystem of flatten(Array.isArray(ids) ? this.getSubtree(ids as string[]) : [this.getSubtree(ids as string)])) {
            if (operatingSystems.map(dl => dl._id).includes(operatingSystem._id)) {
              continue;
            }
            operatingSystems.push(operatingSystem);
          }
          return operatingSystems;
        };
      },
      /**
       * Search for designations that are valid as the new parent of a given operatingSystem.
       * @param id The ID of the operatingSystem for which a valid parent designation is beeing searched.
       * @returns An array of valid designations as the new parent.
       */
      getValidParentDesignations() {
        return (id: string) => {
          const invalidIds = this.getFlatSubtree(id).map(dl => dl._id);
          return this.getDesignations().filter(designation => !invalidIds.includes(designation._id))
        };
      },
      getVersionsOfSubtree() {
        return (ids: string | string[]) => {
          if (!Array.isArray(ids)) {
            ids = [ids];
          }
          return this.getFlatSubtree(ids).filter(version => version.doc_type === 'operating_system_version') as Flat<Tree<Version>>[];
        };
      },
      hasChildren: (state: State) => {
        return ((ids: string | string[], type?: 'designation' | 'version') => {
          if (!Array.isArray(ids)) {
            ids = [ids];
          }
          const featured: boolean[] = [];
          for (const id of ids) {
            switch (type) {
              case 'designation': {
                featured.push(!!state.operatingSystems.filter(dl => dl.doc_type === 'operating_system_designation').find(dl => dl.parent_id === id));
                break;
              }
              case 'version': {
                featured.push(!!state.operatingSystems.filter(dl => dl.doc_type === 'operating_system_version').find(dl => dl.parent_id === id));
                break;
              }
              default: {
                featured.push(!!state.operatingSystems.find(dl => dl.parent_id === id));
                break;
              }
            }
          }
          return ids.length === 1 ? featured[0] : featured;
        }) as ((ids: string | [string], type?: 'designation' | 'version') => boolean) & ((ids: string[], type?: 'designation' | 'version') => boolean[])
      },
    },
    ///-------------------------------------------------------------------
    /// ACTIONS
    ///-------------------------------------------------------------------
    actions: {
      /**
       * Initially loads the data by calling the erlang handler.
       * If the store has already been loaded, no more requests will be sent.
       */
      async load() {
        if (!this.loaded) {
          await this.reload();
        }
      },
      /**
       * Reload the store completely.
       * Calls the erlang handler and replaces the current state with the newly loaded one.
       */
      async reload() {
        this.loaded = false;
        this.loading++;
        try {
          const operatingSystems = await json<OperatingSystem[]>('/api/store/operating-systems', {
            method: 'POST',
            json: {
              action: 'load',
            }
          });
          this.operatingSystems = operatingSystems;
          // if (operatingSystems.length > 0) {
            this.loaded = true;
          // }
        } finally {
          this.loading--;
        }
      },
      /**
       * Creates a new operating system.
       * The operating system will automatically be saved in the database and added to the store.
       * @param operatingSystem The operating system.
       */
      async create(operatingSystem: Partial<OperatingSystem>) {
        this.loading++;
        try {
          const created = await json<OperatingSystem>('/api/store/operating-systems', {
            method: 'POST',
            json: {
              action: 'create',
              operatingSystem: prepare(operatingSystem)
            }
          });
          if (created) {
            this.operatingSystems.push(created);
            return {
              success: true,
              data: created,
            };
          }
        } catch (error: unknown) {
          return {
            success: false,
            error: (error as Error).message,
          }
        } finally {
          this.loading--;
        }
      },
      /**
       * Updates an exisiting operating system.
       * The operating system will automatically be saved in the database and updated in the store.
       * @param operatingSystem The operating system.
       */
      async update(operatingSystem: Partial<OperatingSystem>, attachment?: unknown) {
        this.loading++;
        try {
          const updated = await json<OperatingSystem>('/api/store/operating-systems', {
            method: 'POST',
            json: {
              action: 'update',
              attachment: attachment || null,
              operatingSystem: prepare(operatingSystem)
            }
          });
          if (updated) {
            const index = this.operatingSystems.findIndex((operatingSystem: OperatingSystem) => operatingSystem._id === updated._id);
            this.operatingSystems[index] = updated;
            return {
              success: true,
              data: updated,
            };
          }
        } catch (error: unknown) {
          return {
            success: false,
            error: (error as Error).message,
          }
        } finally {
          this.loading--;
        }
      },
      /**
       * Delete an exisiting operating system.
       * The operating system will automatically be deleted from the database and the store.
       * @param ids The ID or an array of IDs of operating systems to delete.
       */
      async delete(ids: string | string[]) {
        if (!Array.isArray(ids)) {
          ids = [ids];
        }
        this.loading++;
        try {
          const deletedDocs = await json<OperatingSystem[]>('/api/store/operating-systems', {
            method: 'DELETE',
            json: {
              action: 'delete',
              ids
            }
          });
          for (const deleted of deletedDocs) {
            const index = this.operatingSystems.findIndex((operatingSystem: OperatingSystem) => operatingSystem._id === deleted._id);
            this.operatingSystems.splice(index, 1);
          }
          return {
            success: true,
            data: deletedDocs,
          };
        } catch (error: unknown) {
          return {
            success: false,
            error: (error as Error).message,
          }
        } finally {
          this.loading--;
        }
      },
      /**
       * Updates an exisiting operating system.
       * The operating system will automatically be saved in the database and updated in the store.
       * @param operatingSystem The operating system.
       */
      async move(id: string, parent: string) {
        this.loading++;
        try {
          const moved = await json<OperatingSystem>('/api/store/operating-systems', {
            method: 'POST',
            json: {
              action: 'move',
              id,
              parent,
            }
          });
          if (moved) {
            const index = this.operatingSystems.findIndex((operatingSystem: OperatingSystem) => operatingSystem._id === moved._id);
            this.operatingSystems[index] = moved;
            return {
              success: true,
              data: moved,
            };
          }
        } catch (error: unknown) {
          return {
            success: false,
            error: (error as Error).message,
          }
        } finally {
          this.loading--;
        }
      },
    }
  });

  // Preload the local store
  const store = useStore();
  store.load();

  // Return the store reference
  return store;
};