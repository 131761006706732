
import { $gettext, $ngettext } from 'vue-gettext';
import { actionTooltip, Header, toDate } from '@/components/table/BpTable';
import { byKey, Order } from '@/utils/array';
import { computed, defineComponent, ref, watch } from 'vue';
import { currentLanguage, currentLanguageISOString, getTranslated } from '@/translation';
import { flatten, KeyOf } from '@/utils/object';
import { formatBytes, removeHTML, translatedUnitEnumeration } from '@/utils/string';
import { useDownloadStore, Download, File, Folder } from '@/stores/download';
import { OperatingSystem, useOperatingSystemStore } from '@/stores/operatingSystem';
import { useRouter } from 'vue-router';
import { useVersionStore } from '@/stores/version';
import BpToast from '@/components/toast/BpToasts';

export default defineComponent({
  name: 'admin.download-overview',
  setup() {
    ///-------------------------------------------------------------------
    /// STORE
    ///-------------------------------------------------------------------

    const store = useDownloadStore();
    const versionStore = useVersionStore();

    ///-------------------------------------------------------------------
    /// ROUTER
    ///-------------------------------------------------------------------

    const router = useRouter();

    ///-------------------------------------------------------------------
    /// LANGUAGE
    ///-------------------------------------------------------------------

    const lang = computed(() => currentLanguage.value === 'de' ? 'de' : 'en');

    ///-------------------------------------------------------------------
    /// HEADERS
    ///-------------------------------------------------------------------

    const headers = ref<Header[]>([]);
    watch(() => [currentLanguage.value], () => {
      headers.value = [
        {
          _id: 'name.' + currentLanguageISOString(),
          name: $gettext('Name'),
          width: '3fr',
          icon: (download: Download) => '_attachments' in download
            ? store.getIcon(download)
            : expanded.value?.has(download._id) && store.hasChildren(download._id) ? ['far', 'folder-open'] : ['far', 'folder'],
          transform: (download: Download) => '_attachments' in download ? Object.keys(download._attachments).join(', ') : download.name[lang.value],
        },
        {
          _id: 'featured',
          name: $gettext('Featured'),
          width: '0.5fr',
          filter: [
            {
              _id: '_true',
              name: $gettext('Yes'),
              icon: { icon: 'star', color: ['yellow', 500] },
              detect: (_download: Download, featured: Download['featured']) => featured,
            },
            {
              _id: 'false',
              name: $gettext('No'),
              icon: { icon: ['far', 'star'], color: 'var(--theme-text-disabled)' },
              detect: (_download: Download, featured: Download['featured']) => !featured,
            }
          ],
          notSortable: true,
          textAlign: 'center',
          icon: (_download: Download, featured: Download['featured']) => featured
            ? { icon: 'star', color: ['yellow', 500] }
            : { icon: ['far', 'star'], color: 'var(--theme-text-disabled)' },
          transform: () => '',
        },
        {
          _id: 'date',
          name: $gettext('Date'),
          transform: toDate,
        },
        {
          _id: 'description.' + currentLanguageISOString(),
          name: $gettext('Description'),
          width: '1.25fr',
          tooltip: (download: Download) => download.description[lang.value],
          transform: (download: Download) => removeHTML(download.description[lang.value]),
        },
        {
          _id: 'filesize',
          name: $gettext('File size'),
          width: '0.8fr',
          textAlign: 'right',
          transform: (download: Download) => '_attachments' in download
            ? Object.values(download._attachments).map(attachment => formatBytes((attachment.length) as number))
            : '',
        },
        {
          _id: 'hint.' + currentLanguageISOString(),
          name: $gettext('Hint'),
          tooltip: (download: Download) => download.hint[lang.value],
          transform: (download: Download) => removeHTML(download.hint[lang.value]),
        },
        {
          _id: 'compatibilities',
          name: $gettext('Compatibilities'),
          tooltip: (_download: Download, compatibilities: Download['compatibilities']) => compatibilities && operatingSystemStore.loaded ? Object.values(compatibilities
            .reduce((acc, os) => {
              const version = operatingSystemStore.getById(os);
              const designation = operatingSystemStore.getById(version.parent_id);
              return {...acc, [designation._id]: [...designation._id in acc ? acc[designation._id] : [], version]}
            }, {} as Record<string, OperatingSystem[]>))
            .map(versions => `<p>${versions.sort((a, b) => parseFloat(a.name) > parseFloat(b.name) ? -1 : 1).map(v => operatingSystemStore.getName(v)).join(', ')}</p>`)
            .join('') : undefined,
          transform: (_download: Download, compatibilities: Download['compatibilities']) => compatibilities ? compatibilities.map(os => removeHTML(operatingSystemStore.getName(operatingSystemStore.getById(os)))).join(', ') : '',
        },
        {
          _id: 'version',
          name: $gettext('Version'),
          width: '0.8fr',
          transform: (_download: Download, version: File['version']) => versionStore.getVersionById(version) ?? '',
        },
      ];
    }, { immediate: true })

    ///-------------------------------------------------------------------
    /// UPDATE
    ///-------------------------------------------------------------------

    function updateDownload(id: string | string[]) {
      if (Array.isArray(id)) {
        id = id[0];
      }
      const file = store.getById(id);
      if (!file) {
        return;
      }
      const isFile = '_attachments' in file;
      router.push({
        name: isFile ? 'admin.download.file.edit' : 'admin.download.folder.edit',
        params: {
          id
        }
      });
    }

    ///-------------------------------------------------------------------
    /// DELETE CONFIRMATION
    ///-------------------------------------------------------------------

    const confirmation = ref(false);
    const confirmDelete = ref<{
      folders: Folder[],
      files: File[],
    }>({
      folders: [],
      files: []
    });
    const confirmSelectedIds = ref<string[]>([]);

    function showDeleteConfirmation(ids: string | string[]) {
      if (!Array.isArray(ids)) {
        ids = [ids];
      }
      confirmSelectedIds.value = ids;
      const downloads = store.getFlatSubtree(ids);
      confirmDelete.value.folders = (downloads.filter(download => download.doc_type === 'download_folder') as Folder[])
        .sort(byKey(`name.${currentLanguageISOString()}`));
      confirmDelete.value.files = (downloads.filter(download => download.doc_type === 'download_file') as File[])
        .sort((a, b) => store.getName(a) < store.getName(b) ? -1 : 1);
      confirmation.value = true;
    }

    ///-------------------------------------------------------------------
    /// DELETE
    ///-------------------------------------------------------------------

    async function deleteDownload() {
      const folderIDs = confirmDelete.value.folders.map(folder => folder._id);
      const fileIDs = confirmDelete.value.files.map(file => file._id);
      const response = await store.delete([
        ...folderIDs,
        ...fileIDs,
      ]);

      if (response?.success) {
        const folders = response.data?.filter(doc => doc.doc_type === 'download_folder') || [];
        const files = response.data?.filter(doc => doc.doc_type === 'download_file') || [];

        if (folders && files) {
          if (folders.length === folderIDs.length && files.length === fileIDs.length) {
            BpToast.show({
              color: 'green',
              title: $gettext('%{list} successfully deleted', { list: translatedUnitEnumeration({
                folders: [folderIDs.length, folders.length],
                files: [fileIDs.length, files.length],
              }, { includeCount: false }) }),
              content: $ngettext(
                '<strong>%{list}</strong> was successfully deleted.',
                '<strong>%{list}</strong> were successfully deleted.',
                folders.length + files.length,
                {
                  list: translatedUnitEnumeration({
                    folders: [folderIDs.length, folders.length],
                    files: [fileIDs.length, files.length],
                  })
                }),
              icon: 'check',
            });
          } else {
            BpToast.show({
              color: 'red',
              title: $gettext('Failed to delete %{list}', { list: translatedUnitEnumeration({
                folders: [folderIDs.length, folders.length],
                files: [fileIDs.length, files.length],
              }, { difference: true }) }),
              content: [
                $ngettext(
                  '<strong>%{list}</strong> was successfully deleted.',
                  '<strong>%{list}</strong> were successfully deleted.',
                  folders.length + files.length,
                  {
                    list: translatedUnitEnumeration({
                      folders: [folderIDs.length, folders.length],
                      files: [fileIDs.length, files.length],
                    })
                  }),
                $gettext(
                  'Failed to delete <strong>%{list}</strong>.',
                  {
                    list: translatedUnitEnumeration({
                      folders: [folderIDs.length, folders.length],
                      files: [fileIDs.length, files.length],
                    }, { difference: true })
                  }),
              ].join('<br>'),
              icon: 'xmark',
            });
          }
        } else {
          BpToast.show({
            color: 'rose',
            title: 'You should not see this!',
            content: 'Something went terribly wrong!',
            icon: 'triangle-exclamation',
          });
        }
      } else {
        BpToast.show({
          color: 'red',
          title: $gettext('Failed to delete %{list}', { list: translatedUnitEnumeration({
            folders: folderIDs.length,
            files: fileIDs.length,
          }, { includeCount: false }) }),
          content: $gettext('Failed to delete <strong>%{list}</strong>.', { list: translatedUnitEnumeration({
            folders: folderIDs.length,
            files: fileIDs.length,
          }) }),
          icon: 'xmark',
        });
      }
      confirmDelete.value = {
        folders: [],
        files: [],
      };
      confirmation.value = false;
    }

    ///-------------------------------------------------------------------
    /// DRAGGING
    ///-------------------------------------------------------------------

    function dragenter(targetId: string, sourceId: string) {
      document.querySelectorAll('[data-table-row-id]').forEach(el => el.classList.remove('bp-download-overview__highlight'))
      if (targetId !== sourceId && store.getValidParentFolders(sourceId).map(folder => folder._id).includes(targetId)) {
        const el = document.querySelector(`[data-table-row-id="${targetId}"]`) as HTMLElement;
        el.classList.add('bp-download-overview__highlight');
      }
    }

    function dragend(targetId: null, sourceId: string) {
      if (targetId !== sourceId) {
        const el = document.querySelector(`[data-table-row-id="${targetId}"]`) as HTMLElement;
        el.classList.remove('bp-download-overview__highlight');
      }
    }

    function drop(parent: string, id: string) {
      // Remove highlight styling
      document.querySelectorAll('[data-table-row-id]').forEach(el => el.classList.remove('bp-download-overview__highlight'))

      // Move files
      const current = store.getById(id);
      if (parent !== id && store.getValidParentFolders(id).map(folder => folder._id).includes(parent) && current && current.parent_id !== parent) {
        store.move(id, parent)
      }
    }

    ///-------------------------------------------------------------------
    /// SORTING
    ///-------------------------------------------------------------------

    const sortKey = ref<KeyOf<Download>>('order');
    const sortOrder = ref<Order>('asc');

    function sort(sorting: Map<keyof Download, Order>) {
      const [key, order] = sorting.entries().next().value;
      sortKey.value = key;
      sortOrder.value = order;
    }

    ///-------------------------------------------------------------------
    /// EXPANDED
    ///-------------------------------------------------------------------

    const expanded = ref<Set<string>>();

    watch(() => store.isLoading(), () => {
      if (!store.loaded || store.isLoading() || expanded.value) { return }
      expanded.value = new Set(store.getFolders().map(folder => folder._id))
    }, { immediate: true });

    function updateExpanded(value: Set<string>) {
      expanded.value = value;
    }

    ///-------------------------------------------------------------------
    /// QUERY
    ///-------------------------------------------------------------------

    function getQuery(selection: Set<string>) {
      const id = selection.size === 1 ? Array.from(selection)[0] : undefined;
      if (!id) {
        return {};
      }
      return store.isFolder(id) ? { parent: id } : {}
    }

    ///-------------------------------------------------------------------
    /// COMPATIBILITIES
    ///-------------------------------------------------------------------
    
    const operatingSystemStore = useOperatingSystemStore();

    ///-------------------------------------------------------------------
    /// RETURN
    ///-------------------------------------------------------------------

    return {
      actionTooltip,
      confirmation,
      confirmDelete,
      confirmSelectedIds,
      currentLanguageISOString,
      deleteDownload,
      dragend,
      dragenter,
      drop,
      expanded,
      flatten,
      getQuery,
      getTranslated,
      headers,
      showDeleteConfirmation,
      sort,
      sortKey,
      sortOrder,
      store,
      translatedUnitEnumeration,
      updateDownload,
      updateExpanded,
    }
  }
});
